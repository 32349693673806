import {graphql, Link} from 'gatsby';
import React from 'react';
import SEO from '../components/seo';

import Layout from '../components/layout';
import {scale} from '../utils/typography';

interface Props {
  readonly data: PageQueryData;
  readonly pageContext: {
    tag: string;
  };
}

export default class TagTemplate extends React.Component<Props> {
  render() {
    const {pageContext, data} = this.props;
    const {tag} = pageContext;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout>
        <SEO title={tag + ' tag'} />
        <article>
          <header>
            <h2>Posts tagged: `{tag}`</h2>
          </header>
          <div className={`page-content`}>
            {posts.map(({node}) => {
              const title = node.frontmatter.title || node.fields.slug;
              const excerpt = node.frontmatter.excerpt || node.excerpt;
              return (
                <div key={node.fields.slug}>
                  <div>
                    <Link to={node.fields.slug} style={{...scale(1 / 3)}}>
                      {title}
                    </Link>{' '}
                  </div>
                  <small>{node.frontmatter.date}</small>
                  <p dangerouslySetInnerHTML={{__html: excerpt}} />
                </div>
              );
            })}
          </div>
        </article>
      </Layout>
    );
  }
}

interface PageQueryData {
  allMarkdownRemark: {
    totalCount: number;
    edges: {
      node: {
        excerpt: string;
        fields: {
          slug: string;
        };
        frontmatter: {
          date: string;
          title: string;
          excerpt: string;
        };
      };
    }[];
  };
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
          }
        }
      }
    }
  }
`;
